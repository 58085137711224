import stringUtils from "@/commons/utils/string.utils";
import moment from "moment";
import Qs from "qs";

let SESSION_KEY = "HWSSessions";
let SESSION_DATE = "syncDate";
let TIMEOUT = (1000 * 60) * 20;

export default {

	SESSION_KEY() {
		return SESSION_KEY
	},

	created() {

		if (this.getSessionProc() !== undefined) {
			this.$session.set(SESSION_DATE, new Date())
		}

	},

	methods: {

		isMine(_writerKey) {
			if (this.isLogin()) {
				const emailId = this.$session.get(SESSION_KEY).emailId
				if (emailId !== undefined && emailId === _writerKey) {
					return true;
				}
			}

			return false;
		},


		/**
		 * 로그인 페이지로 이동
		 */
		goLogin() {

			let params = (!this.stringUtils.isEmpty(Qs.stringify(this.$route.query))) ? "?" + Qs.stringify(this.$route.query) : "";
			let pathname = location.pathname;
			let referrer = (this.stringUtils.isEmpty(params)) ? "?referrer=" + encodeURIComponent(pathname) : "?referrer=" + encodeURIComponent(pathname + params);
			location.href = "/member/login" + referrer;
			/*this.$router.push({
				name: "login"
			})*/
		},


		/**
		 * 현재 페이지 로그인 페이지로 대체
		 */
		resetLogin() {

			this.$router.replace({
				name: "login"
			})
		},


		/**
		 * 현재 로그인 세션 조회
		 * @returns {*}
		 */
		getSessionProc() {
			if (this.isLogin()) {
				return this.$session.get(SESSION_KEY);
			}
			return null;
		},


		getLoginId() {
			if (this.isLogin()) {
				return this.$session.get(SESSION_KEY).emailId;
			}
			return this.getVueSessionID();
		},


		/**
		 * VUE 세션 ID 조회
		 * @returns {null|*}
		 */
		getVueSessionID() {
			return this.$session.get("session-id");
		},


		/**
		 * 현재 로그인 상태
		 */
		isLogin: function () {
			return (this.$session.get(SESSION_KEY) !== undefined && stringUtils.isEmpty( this.$session.get(SESSION_KEY).emailId ) !== undefined);
		},


		/**
		 * 로그인 확인 후 로그인 화면 이동
		 * @param $this
		 * @param _isCancel false 일 경우 alert 후 강제 로그인 이동
		 * @returns {Promise<unknown>|boolean}
		 */
		requireLogin($this, _isCancel = true) {
			if (!$this.isLogin()) {

				//return new Promise((resolve, reject) => {
				return new Promise(() => {
					// $this.swalUtils.fire("로그인 후 사용하실 수 있습니다.", "확인 버튼을 누르시면 로그인 페이지로 이동합니다.", "info", null, true)
					$this.swalUtils.fire("로그인 후 사용가능합니다.", "로그인 화면으로 이동합니다.", "info", null, _isCancel)
						.then((result) => {

							let temp = "";
							if (Object.keys($this.$route.query).length > 0) {
								temp = "?" + Qs.stringify($this.$route.query)
							}
							temp = location.pathname + temp

							if (result.isConfirmed) {
								location.href = "/member/login?referrer=" + encodeURIComponent(temp);
							} else {
								if (!_isCancel) {
									location.href = "/member/login?referrer=" + encodeURIComponent(temp);
								}
								return false;
							}
						})
				})
			}

			return true;
		},

		/**
		 * 자바에서 체크할 세션 ID
		 * @returns {*}
		 */
		JSESSIONID() {
			if (this.isLogin()) {
				return this.$session.get(SESSION_KEY).sessionId;
			} else {
				return null;
			}
		},


		/**
		 * 세션키에 데이터 입력
		 * @param _obj
		 */
		setSessionProc(_obj, _opener = null) {

			console.log('_obj',_obj)
			const self = this
			return new Promise((resolve) => {

				self.$session.destroy()

				if ( self.stringUtils.isEmpty(_obj.emailId) || self.stringUtils.isEmpty(_obj.userName)) {
					const url = "/member/login"
					if ( _opener == null ) {
						location.href = url
					} else {
						self.appClose({
							url: url
						})
					}

					resolve(false)
					// return false
					return
				}

				self.$session.destroy()
				self.$session[SESSION_DATE] = new Date()
				self.$session.set(SESSION_KEY, _obj)
				self.$session.start()

				resolve(true)

			})
		},


		setSessionKeep(_isKeep) {

			const self = this
			if (this.isLogin()) {
				var temp = self.$session.get(SESSION_KEY)
				temp.isKeepSession = _isKeep;
				self.$session.set(SESSION_KEY, temp);
			}
		},


		doLogout() {

			this.$eventBus.$emit("startProgress", 2)
			this.$nextTick(() => {
				this.$session.destroy()
				this.$eventBus.$emit("doneProgress", 2)
				
				this.swalUtils.gritter("로그아웃 되었습니다", "", "info", 3000)
					.then(() => {
						location.href='/';
					})
			})

		},


		/**
		 * 마지막 데이터 통신 기록시간 저장
		 */
		doSyncdate() {
			const currDate = new Date().getTime()
			this.$session.set(SESSION_DATE, currDate)
			console.log(moment(this.getLastTimes()).format("YYYY-MM-DD HH:mm:ss"))
		},


		getLastTimes() {
			return new Date(this.$session.get(SESSION_DATE));
		},


		isTimeout() {

			// 로그인 유지 옵션 확인
			if (!this.getSessionProc().isKeepSession) {
				if (this.isLogin() && (new Date()).getTime() - this.getLastTimes().getTime() > TIMEOUT) {
					console.log("SESSION TIME OUT!!!")
					this.swalUtils.gritter("로그아웃 됩니다.", "로그인 유지 시간이 초과되어 로그아웃 됩니다.", "info")
					this.doLogout();
					return false;
				}
			}

		},

		getCookie(name) {    //쿠키 존재 확인 (없으면 null 반환)
			var dc,
				prefix,
				begin,
				end;

			dc = document.cookie;
			prefix = name + "=";
			begin = dc.indexOf("; " + prefix);
			end = dc.length;

			if (begin !== -1) {

				begin += 2;
			} else {

				begin = dc.indexOf(prefix);

				if (begin === -1 || begin !== 0) return null;
			}

			if (dc.indexOf(";", begin) !== -1) {
				end = dc.indexOf(";", begin);
			}

			return decodeURI(dc.substring(begin + prefix.length, end)).replace(/\\"/g, '');
			// return decodeURI(dc.substring(begin + prefix.length, end)).replace(/\"/g, '');
		},


	},
	filters: {

		getLoginSession() {
			if (this.$session.get(SESSION_KEY) === undefined) {
				return null;
			}
			return this.$session.get(SESSION_KEY);
		},

	},

}
