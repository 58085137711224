import $ from 'jquery'
import webUtils from '@/commons/utils/web.utils'

let _BASE_PROTOCOL = location.protocol;
let _BASE_HOST = location.hostname;
let _FRONT_HOST = location.hostname;
let _BASE_PORT = location.port;
let _FRONT_PORT = location.port;


/**
 * 혀재 주소가 개발주소일 경우 정의
 * @type {string}
 */
//alert( _BASE_HOST.split(".")[1] + "." + _BASE_HOST.split(".")[2] )
const INNER_HOST = "hwlms.com";
if (_BASE_HOST === "localhost" || _BASE_HOST === "127.0.0.1" || _BASE_HOST.split(".")[1] + "." + _BASE_HOST.split(".")[2] === INNER_HOST) {
// if ( _BASE_HOST === "localhost" || _BASE_HOST === "127.0.0.1" || _BASE_HOST === INNER_HOST ) {
	_BASE_PROTOCOL = "http:";
	_BASE_HOST = "localhost";
	_BASE_PORT = "8080";

	// LOCAL 환경
	// _BASE_HOST = INNER_HOST;
}
// alert( _BASE_HOST );

let _API_URL = _BASE_PROTOCOL + "//" + _BASE_HOST;
let _CMS_URL = _BASE_PROTOCOL + "//" + _BASE_HOST;
if ($.trim(_BASE_PORT) !== "") {
	_API_URL += ":" + _BASE_PORT;
	_CMS_URL += ":" + _BASE_PORT;
}
_API_URL += "/api";
_CMS_URL += "/hwmanager";


/**
 * 프론트 주소 정의
 * @type {string}
 * @private
 */
let _FRONT_URL = _BASE_PROTOCOL + "//" + _FRONT_HOST;
if ($.trim(_BASE_PORT) !== "") {
	_FRONT_URL += ":" + _FRONT_PORT;
}


/**
 * 기본 주소 정의
 * @type {string}
 * @private
 */
let _CSS_URL = _BASE_PROTOCOL + "//" + _BASE_HOST;
let _BASE_URL = _BASE_PROTOCOL + "//" + _BASE_HOST;
if (location.port !== '') {
	_BASE_URL += ":" + location.port;
	_CSS_URL += ":" + _BASE_PORT;
}


if ($.trim(_BASE_PORT) !== "") {
	console.log("_BASE_PROTOCOL ::::: " + _BASE_PROTOCOL)
	console.log("_BASE_HOST ::::: " + _BASE_HOST)
	console.log("_BASE_URL ::::: " + _BASE_URL)
// console.log("_BASE_PORT ::::: " + _BASE_PORT)
	console.log("_FRONT_URL ::::: " + _FRONT_URL)
// console.log("_FRONT_PORT ::::: " + _FRONT_PORT)
	console.log("_API_URL ::::: " + _API_URL)
}

export default {

	FRONT_URL: _FRONT_URL,
	BASE_URL: _BASE_URL,
	CSS_URL: _CSS_URL,
	API_URL: _API_URL,
	_CMS_URL: _CMS_URL,

	APPLE_LOGIN_RETURN: location.origin.replace(":" + location.port, "").replace(webUtils.getSubdomainWithURLString(location.href), "www"),

	// 공통 및 코드
	COMMON_CODE: _API_URL + "/commons/code",
	DP_KEYWORD: _API_URL + "/keywords",


	// DISPLAY + CACHE
	DISPLAY: _API_URL + "/display",
	DISPLAY_CMS_POPUP: _CMS_URL + "/popup",
	DISPLAY_POPUP: _API_URL + "/display/popup",


	// 로그인 및 세션
	SESSION: _API_URL + "/session",
	USER: _API_URL + "/user",


	// SNS 관련
	SNS_APPLE: _API_URL + "/sns/apple",

	// 회원가입
	CO_ACCOUNT: _API_URL + "/coaccounts",

	// 파일 공통
	FILES: _API_URL + "/file",
	FILES_SHOW: _API_URL + "/file/show/",
	FILES_DOWN: _API_URL + "/file/download/",
	FILES_PLAY: _API_URL + "/file/play/",

	// 게시판
	BOARDS: _API_URL + "/boards",
	BOARD_COMMENTS: _API_URL + "/boards/comments",
	BOARD_LIKE: _API_URL + "/boards/like",
	BOARD_CAUTION: _API_URL + "/boards/caution",
	BOARD_FILES: _API_URL + "/boards/file",
	BOARD_FILES_SHOW: _API_URL + "/boards/file/show/",
	BOARD_FILES_DOWN: _API_URL + "/boards/file/download/",
	BOARD_FILES_PLAY: _API_URL + "/boards/file/play/",

	// 서비스 신청
	SERVICE_APPLY: _API_URL + "/service/apply",

	// 나의 서비스
	MY_SERVICE: _API_URL + "/myService",


	// 정적 페이지
	STATIC_PAGE: _API_URL + "/pages/",


	// 내정보
	MINE: _API_URL + '/mypage/info',
	CART: _API_URL + '/mypage/cart',

	OAUTH: _API_URL + '/oauth',

	// PG
	PG_SERVICE: _API_URL + '/pg/service',
	PG_BILL: _API_URL + '/pg/bill',

	// bot
	ASK_BOT: _API_URL + '/bot'
}
//export default APIs;
