import $ from 'jquery'
import Swiper from '@/../public/wapu/assets/js/swiper.min'

$(function () {
    gnb();
    // userLayer();
    sitemap();
    tabmenu();
    mainSlide();
    tabSlide();
    faqList();
    inputFile();
    guideModal();
});

function gnb() {
    var gnb = $(".header .gnb");
    var gnbOpen = gnb.find(".dep1_link, .dep2_list");

    gnbOpen.on("mouseover focusin", function () {
        $(this).parent().addClass("active");
    });
    gnbOpen.on("mouseleave focusout", function () {
        $(this).parent().removeClass("active");
    });
}

// function userLayer() {
//     var userOpen = $(".header .btn_user");
//     var userLayer = $(".header .user_layer");
//
//     userOpen.on("click", function () {
//         userLayer.toggleClass("active");
//     });
//     $(document).on("click", function (e) {
//         if (!userOpen.is(e.target) && userLayer.has(e.target).length === 0) {
//             userLayer.removeClass("active");
//         }
//     });
//
// }

function sitemap() {
    var sitemap = $(".sitemap");
    var sitemapOpen = $(".header .btn_sitemap");
    var sitemapClose = sitemap.find(".btn_close");
    var dep1List = sitemap.find(".dep1_list");
    var dep1Link = dep1List.find(".dep1_link");
    var dep2List = sitemap.find(".dep2_list");

    sitemapOpen.on("click", function () {
        sitemap.stop().fadeIn();
    });
    sitemapClose.on("click", function () {
        sitemap.stop().fadeOut();
    });

    if ($(window).width() >= 1200) {
        sitemap.on("mouseleave focusout", function () {
            $(this).stop().fadeOut();
        });
    }
    if ($(window).width() < 1200) {
        dep1Link.on("click", function () {
            if ($(this).parent("li").hasClass("active")) {
                $(this).parent("li").removeClass("active").find(dep2List).stop().slideUp();
            } else {
                dep1List.children("li").removeClass("active").find(dep2List).slideUp();
                $(this).parent("li").addClass("active").find(dep2List).stop().slideDown();
            }
        });
    }
}

function tabmenu() {
    var tabs = $(".tabs");

    tabs.each(function () {
        var tabMenu = $(this).find(".tab_menu .tab_item");
        var tabCont = $(this).find(".tab_panel");

        tabMenu.on("click", function () {
            var idx = tabMenu.index(this);
            tabCont.hide().eq(idx).show();
            tabMenu.removeClass("active").eq(idx).addClass("active");
        });
        tabMenu.eq(0).click();
    });
}

function mainSlide() {
    new Swiper(".main_visual .visual_wrap", {
        effect: "fade",
        autoplay: {
            delay: 8000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    new Swiper(".main_news .tab_panel", {
        slidesPerView: 4,
        spaceBetween: 40,
        breakpoints: {
            991: {
                slidesPerView: 2.5,
            },
            767: {
                slidesPerView: 1.5,
                spaceBetween: 20,
            },
            575: {
                slidesPerView: 1.5,
                spaceBetween: 20,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        watchSlidesProgress: true,
        observer: true,
        observeParents: true,
    });
}

function tabSlide() {
    new Swiper(".page_tab", {
        slidesPerView: "auto",
        spaceBetween: 36,
        freeMode: true,
        breakpoints: {
            767: {
                spaceBetween: 24,
            },
        },
    });
}

function faqList() {
    var faq = $(".board_faq .faq_item");
    var faqTit = faq.find(".faq_title");
    var faqCont = faq.find(".faq_content");

    faqTit.on("click", function () {
        if ($(this).parent("li").hasClass("active")) {
            $(this).parent("li").removeClass("active").find(faqCont).hide();
        } else {
            faq.children("li").removeClass("active").find(faqCont).hide();
            $(this).parent("li").addClass("active").find(faqCont).show();
        }
    });
}

function inputFile() {
    var btnUpload = $(".btn_upload");
    var inpUpload = $(".inp_upload");
    var inpFile = $(".inp_file");

    btnUpload.on("click", function () {
        $(".inp_file").click();
    });
    inpFile.on("change", function () {
        var filePath = $(this).val();
        inpUpload.val(filePath);
    });
}

function guideModal() {
    var modal = $(".modal");
    var modalClose = modal.find(".btn_close");
    var modalContent = modal.find(".modal_content");
    var modalOpen = $(".btn_modal");
    
    modalOpen.on("click", function () {
        modal.fadeIn();
        var id = $(this).data("id");
        $('.modal_content[data-id="' + id + '"]').fadeIn();
    });
    modalClose.on("click", function () {
        modal.fadeOut();
        modalContent.fadeOut();
    });
}

export {
	gnb,
	//userLayer,
	sitemap,
	tabmenu,
	mainSlide,
	tabSlide,
	faqList,
	inputFile,
	guideModal,
}