import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	mode: "history",
	pageRouting: true,
	base: process.env.BASE_URL,
	scrollBehavior(e) {
		console.log(e)
		if ( e.query.scrollTop === undefined || e.query.scrollTop === true ) {
			window.scrollTo({top:0, behavior:'smooth'})
		}
	},
	routes: [
		{
			name: 'index',
			path: '/',
			components: {
				'rv-app': () => import('@/components/layouts/layoutPage')
			},
			children: [
				{
					path: '/',
					components: {
						'rv-container': () => import('@/pages/index')
					}
				},
			]
		},

		{
			// 유저 관련 서비스
			name: 'member',
			path: '/member',
			components: {
				'rv-app': () => import('@/components/layouts/layoutPage')
			},
			children: [
				{
					path: 'login',
					components: {
						'rv-container': () => import('@/components/member/login')
					}
				},
				{
					path: 'signUp',
					components: {
						'rv-container': () => import('@/components/member/signUp')
					}
				},
				{
					path: 'quit',
					components: {
						'rv-container': () => import('@/components/member/quit')
					}
				},
				{
					path: 'successQuit',
					components: {
						'rv-container': () => import('@/components/member/successQuit')
					}
				},
			]
		},


		{
			// 서비스
			name: 'service',
			path: '/service',
			components: {
				'rv-app': () => import('@/components/layouts/layoutPage')
			},
			children: [
				{
					// 기본정보
					name: 'apply_info',
					path: 'info',
					components: {
						'rv-container': () => import('@/pages/service/info')
					}
				},
				{
					// 데이터 및 결제정보
					name: 'apply_data',
					path: 'data',
					components: {
						'rv-container': () => import('@/pages/service/data')
					}
				},
				{
					// 입력 정보 확인
					name: 'apply_check',
					path: 'check',
					components: {
						'rv-container': () => import('@/pages/service/check')
					}
				},

				//------
				// PG
				//------
				{
					// PG INICIS 창 닫기
					name: 'closePg',
					path: 'closePg',
					components: {
						'rv-container': () => import('@/components/pg/closePg')
					}
				},
			]
		},



		{
			// MY 서비스
			name: 'myService',
			path: '/myService',
			components: {
				'rv-app': () => import('@/components/layouts/layoutPage')
			},
			children: [
				{
					// 나의 서비스 리스트
					name: 'myList',
					path: 'myList',
					components: {
						'rv-container': () => import('@/components/myService/myList')
					}
				},
				{
					// 나의 서비스 상세
					name: 'myView',
					path: 'myView',
					components: {
						'rv-container': () => import('@/components/myService/myView')
					}
				},
				{
					// 나의 결제 이력보기 리스트
					name: 'historyList',
					path: 'historyList',
					components: {
						'rv-container': () => import('@/components/myService/historyList')
					}
				},
				{
					// 나의 결제 이력 상세
					name: 'historyView',
					path: 'historyView',
					components: {
						'rv-container': () => import('@/components/myService/historyView')
					}
				},
				{
					// 결제 정보 관리
					name: 'paymentOption',
					path: 'paymentOption',
					components: {
						'rv-container': () => import('@/components/myService/paymentOption')
					}
				},
				{
					// 초대하기 리스트
					name: 'inviteList',
					path: 'inviteList',
					components: {
						'rv-container': () => import('@/components/myService/inviteList')
					}
				},
				{
					// 초대하기 상세
					name: 'inviteView',
					path: 'inviteView',
					components: {
						'rv-container': () => import('@/components/myService/inviteView')
					}
				},
				{
					// 분석보고서
					name: 'analysis',
					path: 'analysis',
					components: {
						'rv-container': () => import('@/components/myService/analysis')
					}
				},
				{
					// 모니터링보고
					name: 'monitoring',
					path: 'monitoring',
					components: {
						'rv-container': () => import('@/components/myService/monitoring')
					}
				},

			]
		},



		{
			// Support Services
			name: 'support',
			path: '/support',
			components: {
				'rv-app': () => import('@/components/layouts/layoutPage'),
			},
			children: [
				{
					// 문의하기
					name: 'qna',
					path: 'qna',
					components: {
							'rv-container': () => import('@/pages/support/qna')
					}
				},

				// 약관
				{
					// 이용약관
					name: 'terms',
					path: 'terms',
					components: {
						'rv-container': () => import('@/components/information/terms')
					}
				},
				{
					//  개인정보 처리방침
					name: 'privacy',
					path: 'privacy',
					components: {
						'rv-container': () => import('@/components/information/privacy')
					}
				},
				{
					//  이메일무단수집거부
					name: 'email',
					path: 'email',
					components: {
						'rv-container': () => import('@/components/information/email')
					}
				},
				{
					//  서비스 소개
					name: 'serviceInfo',
					path: 'serviceInfo',
					components: {
						'rv-container': () => import('@/components/information/serviceInfo')
					}
				},
				{
					//  회사 소개
					name: 'company',
					path: 'company',
					components: {
						'rv-container': () => import('@/components/information/company')
					}
				},
			],
		},





		{
			// 사용 후기
			name: 'review',
			path: '/review',
			components: {
				'rv-app': () => import('@/components/layouts/layoutPage'),
			},
			children: [
				{
					// 사용후기
					name: 'boardKey',
					path: ':boardKey',
					components: {
							'rv-container': () => import('@/pages/boards/reviewList')
					},
				},
				{
					// form
					name: 'form',
					path: ':boardKey/form',
					components: {
							'rv-container': () => import('@/components/boards/forms/formBasic')
					}
				},
				{
					// form
					name: 'view',
					path: ':boardKey/view',
					components: {
							'rv-container': () => import('@/components/boards/forms/formView')
					}
				},
			],
		},

		{
			// ,etc
			name: 'board',
			path: '/board',
			components: {
				'rv-app': () => import('@/components/layouts/layoutPage'),
			},
			children: [
				{
					// 사용후기
					name: 'boardKey',
					path: ':boardKey',
					components: {
							'rv-container': () => import('@/pages/boards/noticeList')
					}
				},
				{
					// form
					name: 'view',
					path: ':boardKey/view',
					components: {
						'rv-container': () => import('@/components/boards/forms/formView')
					}
				},
			],
		},


		{
			// ,etc
			name: 'faq',
			path: '/faq',
			components: {
				'rv-app': () => import('@/components/layouts/layoutPage'),
			},
			children: [
				{
					// 사용후기
					name: 'faq',
					path: '',
					components: {
							'rv-container': () => import('@/pages/boards/faqList')
					}
				},
			],
		},

		{
			// bot
			name: 'elandopenai',
			path: '/elandopenai',
			components: {
				'rv-app': () => import('@/components/bot/openAI'),
			},
		},



		{
			/**
			 * ERROR
			 * route 등록 외 다른 페이지 없는 페이지
			 */
			name: 'error',
			path: '/:catchAll(.*)',
			components: {
				'rv-app': () => import('@/components/layouts/layoutPage')
			},
			children: [
				{
					path: '/',
					components: {
						'rv-container': () => import('@/components/layouts/error')
					}
				},
			]
		},


	],
});
