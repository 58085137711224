<template>
	<!DOCTYPE html>
	<html id="app" lang="ko">
	<head>
		<meta charset="UTF-8">
		<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
		<meta http-equiv="X-UA-Compatible" content="IE=edge">
		<meta name="viewport" content="width=device-width, initial-scale=1.0">
		<title>WAPU</title>
		
<!--	naver 검색 엔진 등록	-->
<!--		<meta name="naver-site-verification" content="074a373cb38bcaf4633188f46e8ec8759191ba7b" />-->
		<meta name="description" content="WISE N PURE" />
	</head>
	<body>
<!--	<body oncontextmenu="return false" ondragstart="return false" onselectstart="return false">-->

		<div class="wrap" :class="{
			'main': (pathname === '/')
			, 'apply': (pathname !== '/')
			, 'payment_option' : (pathname === '/myService/paymentOption')
			, 'my_service' : (pathname === '/myService/myList')
			, 'service' : (pathname === '/support/serviceInfo')
			, 'company' : (pathname === '/support/company')
		}">

<!--			<transition name="fade" mode="out-in">
				<router-view name="rv-app" :key="$route.fullPath"></router-view>
			</transition>-->

			<router-view name="rv-app" ></router-view>

		</div>

	</body>

	<progress-bars></progress-bars>
<!--	<vue-topprogress ref="topProgress" :speed="500" :height="5"></vue-topprogress>-->

	</html>

</template>

<script>

import commonsMixin from '@/commons/mixins/commons.mixin'
import sessionsMixin from '@/commons/mixins/sessions.mixin'
import * as commonjs from "../public/wapu/assets/js/common";

import Vue from "vue";
import vueTopprogress from 'vue-top-progress'
import ProgressBars from "@/commons/progressBars";

Vue.use(vueTopprogress)


export default {
	name: "App",
	components: {ProgressBars},
	mixins: [
		commonsMixin,
		sessionsMixin,
	],
	data() {

		return {
			pathname: location.pathname,
		}
	},
	created() {


		//const self = this
		//this.setTitles("WAPU-BIM설계 올인원 플랫폼");



		// 백버튼 시 새로고침으로 동작변경
		window.onpopstate = () => {
			this.$nextTick(() => {
				// history.back()
				//location.reload()
				// self.created()
			})
		}


		/*this.$eventBus.$on("startProgress", ()=> {
			this.$refs.topProgress.start()
		})

		this.$eventBus.$on("doneProgress", ()=> {
			this.$refs.topProgress.done()
		})*/

	},
	mounted() {
		const self = this;

		this.doSyncdate();
		this.doCheckTimeout();

		this.$eventBus.$on("initApp", ( )=> {
			self.initApp()
		})
	},
	updated() {
		window.setTimeout(() => {
			this.$nextTick(() => import('@/../public/wapu/assets/js/common'))
		}, 500)
	},
	methods: {
		initApp () {
			console.log("init App")
			setTimeout(() => {
				this.$nextTick(() => {
					commonjs.gnb();
					//commonjs.userLayer();
					commonjs.sitemap();
					commonjs.tabmenu();
					commonjs.mainSlide();
					commonjs.tabSlide();
					commonjs.faqList();
					commonjs.inputFile();
					commonjs.guideModal();
				})
			}, 500)
		},

		doCheckTimeout() {

			console.log( this.getLastTimes() )
			setTimeout(()=>{

				if ( this.isLogin() ) {
					this.isTimeout();
				}

				this.doCheckTimeout();
			}, (1000 * 60) * 1 )
		}

	},
	watch: {
		'currentTimes': function (newVal) {
			console.log( newVal )
		}
	}
}
</script>

<style scoped>
/*@import '/wapu/assets/css/app.min.css';
@import '/wapu/assets/css/style.css';
@import '/wapu/assets/css/style-inicis.css';*/

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}

[v-cloak]::before {
	content: '로딩중...'
}
[v-cloak] > * {
	display:none;
}
</style>
