

import $ from 'jquery'
const PAGE_SIZE = 10;


import webUtils from '@/commons/utils/web.utils.js'

const PageUtils = {


	pushState: (_params) => {
		history.pushState( _params, null, location.pathname + "?" + webUtils.serialize(_params) )
	},


	/**
	 * vue 페이지 이동
	 * @param $this
	 * @param _query
	 */
	routerPush: ($this, _query) => {

		delete _query.LOGIN_ID
		delete _query.LOGIN_NICK
		delete _query.JSESSIONID

		$this.$router.push({
			query: _query,
		},).catch((e) => {
			console.log(e)
		})
	},


	/**
	 * 현재 주소 변경 + 콜백 실행
	 * @param $this
	 * @param _query
	 * @param _callback
	 */
	routerPush2: ($this, _query, _callback) => {

		delete _query.LOGIN_ID
		delete _query.LOGIN_NICK
		delete _query.JSESSIONID

		history.pushState( _query, null, location.pathname + "?" + webUtils.serialize(_query) )
		_callback();
	},



	getDefuletSort: ( _defaultSort ) => {
		if( _defaultSort === undefined || _defaultSort == null || _defaultSort === "") {
			_defaultSort = 'createDate,DESC'
		}
		return _defaultSort;
	},


	Pageable: function(_defaultSort) {

		_defaultSort = PageUtils.getDefuletSort(_defaultSort);

		var temp = {};
		temp.page = webUtils.getParameterInt("page")
		temp.size = (webUtils.getParameterInt("size") === 0) ? PAGE_SIZE : webUtils.getParameterInt("size");
		temp.sort = webUtils.getParameterInt("sort") === "" || webUtils.getParameterInt("sort") == null ? _defaultSort : decodeURIComponent(webUtils.getParameter("sort"))
		temp.totalCount = 0;
		return temp;
	},



	Params: function(_defaultSort) {

		_defaultSort = PageUtils.getDefuletSort(_defaultSort);

		var temp = {};
		temp.mode = (webUtils.getParameter("mode") === "") ? "list" : webUtils.getParameter("mode");

		temp.page = webUtils.getParameterInt("page")
		temp.size = (webUtils.getParameterInt("size") === 0) ? PAGE_SIZE : webUtils.getParameterInt("size");
		temp.sort = webUtils.getParameter("sort") === "" || webUtils.getParameter("sort") == null ? _defaultSort : decodeURIComponent(webUtils.getParameter("sort"))
		//temp.totalCount = 0;

		temp.searchKey = webUtils.getParameter("searchKey") === "" || webUtils.getParameter("searchKey") == null ? "" : decodeURIComponent(webUtils.getParameter("searchKey"))
		temp.searchText = webUtils.getParameter("searchText") === "" || webUtils.getParameter("searchText") == null ? "" : decodeURIComponent(webUtils.getParameter("searchText"))
		temp.statusCode = webUtils.getParameter("statusCode") === "" || webUtils.getParameter("statusCode") == null ? "" : decodeURIComponent(webUtils.getParameter("statusCode"))

		return temp;
	},


	/*function setPageable(  _pageagle ) {
		return {
			page: _pageagle.pageNumber,
			size: _pageagle.pageSize,
			// sort: _pageagle.sort,
		}
	}*/



	doSort: function ( pageable, colName) {

		if ( pageable.sort.split(',')[0] !== colName ) {
			pageable.sort = colName + ',DESC'
			$("span[id^=i-]").removeClass('sort-asc sort-desc sort-none').addClass('sort-none')
			$('#i-'+colName).addClass("sort-desc")
			return pageable.sort
		} else {

			if ( pageable.sort.split(',')[1] === 'DESC' ){
				pageable.sort = colName + ',ASC'

				// $("span[class^=sort-]").removeClass()
				$("span[id^=i-]").removeClass('sort-asc sort-desc sort-none').addClass('sort-none')

				$('#i-'+colName).addClass("sort-asc")
			}else{
				pageable.sort = colName + ',DESC'
				$("span[id^=i-]").removeClass('sort-asc sort-desc sort-none').addClass('sort-none')
				$('#i-'+colName).addClass("sort-desc")
			}

		}

		return pageable.sort
	},





	setSearch: function (_param) {

		var pageable = new this.Pageable();
		pageable.page = 0
		pageable.sort = "createDate,DESC"
		pageable.size = _param.size

		return pageable;
	},


}
export default PageUtils;







