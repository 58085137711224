
import $ from 'jquery'

const StringUtils = {

	isEmpty: function($_str) {
		if ( $_str === undefined || $_str === null || $.trim( $_str ) === '') {
			return true;
		} else {
			return false;
		}
	},


	val ($_str) {
		if (this.isEmpty($_str)) {
			return "";
		} else {
			return $_str;
		}
	},


	valInt ($_str) {
		if (this.isEmpty($_str)) {
			return 0;
		} else {
			return parseInt($_str);
		}
	},


	nl2br: function(str) {
		if(!str){
			return ''
		}
		return str.replace(/\r\n|\n/g, '<br />')
	},


	idFilter ( userId ) {
		if ( userId.length < 4 ){
			return userId
		}else{
			switch (userId.length) {
				case 4:
					return userId.substr(0,3) + '*'
				case 5:
					return userId.substr(0,3) + '**'
				default:
					return userId.substr(0,3) + '**' + userId.substr(5, userId.length)
			}
		}
	},

	toBase64(string) { return window.btoa(unescape(encodeURIComponent(string))) },
	toUTF8(string) { return decodeURIComponent(escape(window.atob(string))) },

	// 소문자 검증
	isSmallLetter(string) {
		const myRe = /^[a-z]*$/;
		return myRe.test(string);
	},

	// 소문자 + 숫자 검증
	isSmallLetterAndNumber(string) {
		const myRe = /^[a-z0-9]*$/;
		return myRe.test(string);
	},

	isNumber(string) {
		const myRe = /^[0-9]*$/;
		return myRe.test(string);
	},

	// 첫글자 숫자인가? 숫자면 true 리턴
	isFirstCharNum(string) {
		let first_char = string.charAt(0);
		return !isNaN(first_char)
	},




}
export default StringUtils;