var render = function render(){var _vm=this,_c=_vm._self._c;return _c('html',{attrs:{"id":"app","lang":"ko"}},[_vm._m(0),_c('body',[_c('div',{staticClass:"wrap",class:{
			'main': (_vm.pathname === '/')
			, 'apply': (_vm.pathname !== '/')
			, 'payment_option' : (_vm.pathname === '/myService/paymentOption')
			, 'my_service' : (_vm.pathname === '/myService/myList')
			, 'service' : (_vm.pathname === '/support/serviceInfo')
			, 'company' : (_vm.pathname === '/support/company')
		}},[_c('router-view',{attrs:{"name":"rv-app"}})],1)]),_c('progress-bars')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('head',[_c('meta',{attrs:{"charset":"UTF-8"}}),_c('meta',{attrs:{"http-equiv":"Content-Type","content":"text/html; charset=utf-8"}}),_c('meta',{attrs:{"http-equiv":"X-UA-Compatible","content":"IE=edge"}}),_c('meta',{attrs:{"name":"viewport","content":"width=device-width, initial-scale=1.0"}}),_c('title',[_vm._v("WAPU")]),_c('meta',{attrs:{"name":"description","content":"WISE N PURE"}})])
}]

export { render, staticRenderFns }