
// const vue = require('vue');
const axios = require('axios').default;
import Qs from 'qs'

const AxiosCaller = {


	sendSlim (_data ) {
		_data = ( _data == null ) ? {} : _data;
		_data.userId = _data.LOGIN_ID

		delete _data.status

		delete _data.createDate
		delete _data.updateDate
		delete _data.createUserId
		delete _data.updateUserId
		delete _data.startSearchDate
		delete _data.endSearchDate

		delete _data.commentList
		delete _data.attachList
		delete _data.thumbFile
		delete _data.cateVO
		delete _data.codeVO

		delete _data.startDate
		delete _data.endDate

		delete _data.coInfoVO
	},

	sendSlimForService (_data ) {
		_data = ( _data == null ) ? {} : _data;
		_data.userId = _data.LOGIN_ID

		delete _data.status

		delete _data.createDate
		delete _data.updateDate
		delete _data.createUserId
		delete _data.updateUserId
		delete _data.startSearchDate
		delete _data.endSearchDate

		delete _data.commentList
		delete _data.attachList
		delete _data.thumbFile
		delete _data.cateVO
		delete _data.codeVO

		delete _data.coInfoVO
	},

	returnSlim ( _data ) {
		_data = ( _data == null ) ? {} : _data;
		delete _data.userId
		delete _data.LOGIN_ID
		delete _data.LOGIN_NICK
		delete _data.LOGIN_DOMAIN
		delete _data.createUserId
		delete _data.updateuserId
		delete _data.updateDate
	},


	logError ( _res ) {
		/**
		 * 에러 일 경우 콘솔 표출
		 * 본 소스에서는 ok, fail 만 처리
		 */
		if ( _res.data.status === "error" ) {
			console.log( _res.data.msg )
		}
	},



	setSessionParams ( _data, $this ) {


		_data = ( _data == null ) ? {} : _data;
		// _data.JSESSIONID = $this.JSESSIONID();
		// _data.LOGIN_DOMAIN = _data.LOGIN_DOMAIN ? _data.LOGIN_DOMAIN : $this.webUtils.getSubdomain();
		// _data.coCode = _data.coCode ? _data.coCode : _data.LOGIN_DOMAIN;

		if ( $this.isLogin() ) {
			_data.LOGIN_ID = $this.getSessionProc().userId;
			_data.LOGIN_NICK = $this.getSessionProc().managerName;
		} else {
			_data.LOGIN_ID = $this.getVueSessionID();
			_data.LOGIN_NICK = $this.getVueSessionID();
		}
	},

	postFormData( $this, _url, _data, _callback, _catch=null ) {
		// axios.post(this.APIs.BOARD_FILES + "/upload", formData,{
		// 	headers: {
		// 		'Content-Type': 'multipart/form-data'
		// 	}
		// }).then((response) => {
		// 	// 응답 처리
		// }).catch((error) => {
		// 	// 예외 처리
		// })

		$this.doSyncdate()
		this.setSessionParams(_data, $this);
		this.sendSlim(_data);
		axios({
			method: 'post',
			url: _url,
			data: _data,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
				.then( (res) => {
					// console.log("post then 1")
					this.returnSlim( res.data )
					this.returnSlim( _data )
					this.logError(res);

					_callback(res)
				})
				.catch(reason => {
					console.log( reason )
					if ( _catch != null ) {
						_catch(reason);
					}
				})
				.then( () => {
					// console.log("post then 2")
				});
	},

	get( $this, _url, _data, _callback, _catch = null ) {

		$this.doSyncdate()
		this.setSessionParams(_data, $this);
		this.sendSlim(_data);
		axios.get(_url, {
			params: _data
		})
		.then( (res) => {
			// console.log("get then 1")
			this.returnSlim( res.data )
			this.returnSlim( _data )
			this.logError(res);

			_callback(res)
		})
		.catch(reason => {
			console.log( reason )
			if ( _catch != null ) {
				_catch(reason);
			}
		})
		.then( () => {
			// console.log("get then 2")
		});

	},


	put( $this, _url, _data, _callback ) {
		this.process($this, "put", _url, _data, _callback )
	},


	post( $this, _url, _data, _callback ) {

		this.process($this, "post", _url, _data, _callback )

	},

	servicePost( $this, _url, _data, _callback ) {

		this.processService($this, "post", _url, _data, _callback )

	},

	patch( $this, _url, _data, _callback ) {

		this.process($this, "patch", _url, _data, _callback )
	},

	delete( $this, _url, _data, _callback, _catch = null ) {

		//this.process($this, "delete", _url, _data, _callback )
		$this.doSyncdate()
		this.setSessionParams(_data, $this);
		this.sendSlim(_data);
		axios.delete(_url, {
			params: _data,
		})
			.then( (res) => {
				// console.log("post then 1")
				this.returnSlim( res.data )
				this.returnSlim( _data )
				this.logError(res);

				_callback(res)
			})
			.catch(reason => {
				console.log( reason )
				if ( _catch != null ) {
					_catch(reason);
				}
			})
			.then( () => {
				// console.log("post then 2")
			});
	},


	process ( $this, _methods, _url, _data, _callback, _catch = null ) {

		$this.doSyncdate()
		this.setSessionParams(_data, $this);
		this.sendSlim(_data);
		axios({
			method: _methods,
			url: _url,
			data: _data,
			maxContentLength: Infinity,
			maxBodyLength: Infinity,
			transformRequest: [function (data) {
				// Any conversion of data
				// console.log(data)
				// console.log( Qs.stringify(data) )
				return Qs.stringify(data)
			}],
		})
			.then( (res) => {
				// console.log("post then 1")
				this.returnSlim( res.data )
				this.returnSlim( _data )
				this.logError(res);

				_callback(res)
			})
			.catch(reason => {
				console.log( reason )
				if ( _catch != null ) {
					_catch(reason);
				}
			})
			.then( () => {
				// console.log("post then 2")
			});
	},

	processService ( $this, _methods, _url, _data, _callback, _catch = null ) {

		$this.doSyncdate()
		this.setSessionParams(_data, $this);
		this.sendSlimForService(_data);
		axios({
			method: _methods,
			url: _url,
			data: _data,
			maxContentLength: Infinity,
			maxBodyLength: Infinity,
			transformRequest: [function (data) {
				// Any conversion of data
				// console.log(data)
				// console.log( Qs.stringify(data) )
				return Qs.stringify(data)
			}],
		})
				.then( (res) => {
					// console.log("post then 1")
					this.returnSlim( res.data )
					this.returnSlim( _data )
					this.logError(res);

					_callback(res)
				})
				.catch(reason => {
					console.log( reason )
					if ( _catch != null ) {
						_catch(reason);
					}
				})
				.then( () => {
					// console.log("post then 2")
				});
	}


}
export default AxiosCaller;