import Vue from 'vue'


/**
 * 현재수와 합수로 평균 도출
 */
Vue.filter("avg", function (entry, sum) {

	let res = Math.ceil(entry / sum * 100);
	return isNaN(res) ? 0 : res;
})


Vue.filter("currency", (value) => {
	if (typeof value === 'undefined') {
		return
	}
	return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
});

// /**
//  * 세 자리 마다 콤마
//  */
// Vue.filter("comma", (value) => {
// 	if (typeof value === 'undefined') {
// 		return
// 	}
// 	return value.toLocaleString('ko-KR');
// 	// return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// })
