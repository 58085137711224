export default {

	created() {

		//this.defaultTitle();

	},

	methods: {

		defaultTitle: function () {
			document.title = "HWSolutions"
		},

		setTitles: function (_titles) {
			document.title = _titles;
		},


		goMain() {
			location.href = "/"
		},



		getCodeOne ( _code ) {

			return new Promise( (resolve, reject) => {

				this.$eventBus.$emit("startProgress", 4)
				this.axiosCaller.get(this, this.APIs.COMMON_CODE + "/codeOne", {
					code: _code
				}, (res) => {
					this.$eventBus.$emit("doneProgress", 4)

					const result = res.data;
					// console.log( result )
					resolve( result )
				}, (res) => {
					reject(res)
				})
			})

		},



		openStaticPage ( _url ) {
			_url = ( !_url.startsWith("http") ) ? this.APIs.BASE_URL + _url : _url;
			this.appOpen({
				url: _url
			})
		},



	}

}