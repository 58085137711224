import "@babel/polyfill";
import Vue from 'vue'




// set vue-session
import VueSession from 'vue-session'
Vue.use(VueSession, {
  persist: true
})



// eventBus definitions
Vue.prototype.$eventBus = new Vue();






// add library
import $ from 'jquery'
// import jQuery from 'jquery'
window.$ = $


// add publishing bootstrap
import('../public/wapu/assets/js/bootstrap.bundle.min')




// add mixins
import CommonsMixin from '@/commons/mixins/commons.mixin'   // 공통
import SessionMixin from '@/commons/mixins/sessions.mixin'  // 세션
import HWAPP from '@/commons/mixins/hwapp.mixin'  // 앱 브릿지

import BoardVOs from '@/commons/vo/boards'  // 게시판
import DisplayVOs from '@/commons/vo/displays' //주문
import ServiceApplyVOs from '@/commons/vo/serviceApply' //서비스 신청 정보


Vue.mixin(CommonsMixin)
Vue.mixin(SessionMixin)
Vue.mixin(HWAPP)
Vue.mixin(BoardVOs)
Vue.mixin(DisplayVOs)
Vue.mixin(ServiceApplyVOs)




// add utils
import APIs from '@/commons/APIs'
import axiosCaller from '@/commons/utils/axios.caller'
import webUtils from '@/commons/utils/web.utils'
import pageUtils from '@/commons/utils/page.utils'
import stringUtils from '@/commons/utils/string.utils'
import arrayUtils from '@/commons/utils/array.utils'
import numberUtils from '@/commons/utils/number.utils'
import swalUtils from '@/commons/utils/swal.utils'
import codeUtils from '@/commons/utils/code.utils'
import mediaUtils from '@/commons/utils/media.utils'

Vue.prototype.$ = $;
Vue.prototype.APIs = APIs;
Vue.prototype.axiosCaller = axiosCaller;
Vue.prototype.webUtils = webUtils;
Vue.prototype.pageUtils = pageUtils;
Vue.prototype.stringUtils = stringUtils;
Vue.prototype.arrayUtils = arrayUtils;
Vue.prototype.numberUtils = numberUtils;
Vue.prototype.swalUtils = swalUtils;
Vue.prototype.codeUtils = codeUtils;
Vue.prototype.mediaUtils = mediaUtils;

window.$ = $
window.jQuery = $
window.APIs = APIs
window.webUtils = webUtils
window.pageUtils = pageUtils
window.stringUtils = stringUtils
window.arrayUtils = arrayUtils
window.numberUtils = numberUtils
window.swalUtils = swalUtils
window.codeUtils = codeUtils
window.mediaUtils = mediaUtils





// add filters
import "@/commons/filters/array.filter"
import "@/commons/filters/date.filter"
import "@/commons/filters/number.filter"
import "@/commons/filters/string.filter"




// External JS LOAD Library
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);



// add vue-sweetalert2
import vueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '../public/wapu/assets/css/sweetalert2.css';
Vue.use(vueSweetalert2)





// Font Awesome
import '@fortawesome/fontawesome-free/js/all'
// import '@fortawesome/fontawesome-free/js/fontawesome'
// import '@fortawesome/fontawesome-free/js/brands'



// add Message Kor
/*import message_kor from '@/commons/mixins/messages_kor.mixin'
window.$messages = message_kor
Vue.prototype.$messages = message_kor*/



Vue.config.productionTip = false


import App from '@/App'
import routes from './vue.routes'

	new Vue({
		render: h => h(App),
		router: routes,
	}).$mount('#app')
	// import('@/../public/wapu/assets/js/common')


//document.title = "HWSolutions";

// add publishing common-ui
//import('../public/assets/js/common-ui')
/*setTimeout( () => {
  import('../public/assets/js/common-ui')
}, 500)*/
/*
this.$nextTick(() => {
  import('../public/assets/js/common-ui')
})*/
