import $ from 'jquery'
import Vue from "vue";

const WebUtils = {

	getSubdomain() {
		/*console.log( location.path )
		console.log( location.pathname )
		console.log( location.origin )
		console.log( location.host )
		console.log( location.hostname )*/

		if (location.hostname.indexOf(".") > -1) {

			var subDomain = location.hostname.split('.').length >= 3 ? location.hostname.split(".")[0] : 'www'
			if (subDomain === "localhost" || !isNaN(subDomain)) {
				subDomain = "www";
			}

			return subDomain;
			// var subDomain = location.hostname.split(".")[0];
			// if ( subDomain === "localhost" || !isNaN(subDomain) ) {
			// 	subDomain = "www";
			// }
			//
			// return subDomain;
		}

		// return location.hostname
		return "www"
	},

	/**
	 * 파라미터를 url 로 하는 url 에서 subdomain 추출
	 *
	 * @param _urlString
	 */
	getSubdomainWithURLString(_urlString) {
		let hostname = new URL(_urlString).hostname
		if (hostname.indexOf(".") > -1) {

			var subDomain = hostname.split('.').length >= 3 ? hostname.split(".")[0] : 'www'
			if (subDomain === "localhost" || !isNaN(subDomain)) {
				subDomain = "www";
			}

			return subDomain;
		}

		return "www"
	},

	getParameter: function (strParamName) {
		var arrResult = null;
		if (strParamName) {
			arrResult = location.search.match(new RegExp("[&?]" + strParamName + "=(.*?)(&|$)"));
		}
		return arrResult && arrResult[1] ? decodeURIComponent(arrResult[1]) : "";
	},

	getParameterInt: (strParamName) => {
		if (WebUtils.getParameter(strParamName) === "") {
			return 0;
		} else {
			return WebUtils.getParameter(strParamName);
		}
	},


	serialize: (obj) => {
		var str = [];
		for (var p in obj)
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(p)) {
				str.push(encodeURIComponent(p) + "=" + obj[p]);
				// str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
			}
		return str.join("&");
	},


	scrollTo: (_domId) => {

		return new Promise(res => {
			if ($(_domId).position() == null) {
				_domId = "header"
			}

			let timer = null

			window.addEventListener('scroll', function () {
				if (timer !== null) {
					clearTimeout(timer)
				}
				timer = setTimeout(function () {
					res()
				}, 150)
			}, false)

			window.scrollTo({
				top: $(_domId).position().top,
				left: $(_domId).position().left,
				behavior: 'smooth'
			});
		})
	},


	isDom: ($_dom) => {

		const tmp = document.querySelector($_dom);
		if (tmp !== null && tmp !== undefined) {
			return true;
		}

		return false;
	},

	/**
	 * $router.push extension
	 *
	 * 1. router.push 실행
	 * 2. 현재 라우터 에서 현재 라우터 호출시 오류 캐치 후 현재 라우터 새로 고침
	 * 3. 이외 오류 캐치 후 router name 이 있을 때 router name 으로 path 파싱 후 이동
	 * 4. this 가 Vue 가 아니면 location.href 액션
	 * 5. router 가 url 이면 url 파싱 후 path 로 사용
	 * 6. 파싱 실패 시 location.href 액션
	 *
	 * @param _this
	 * @param _router
	 * @param _replace
	 * @param _then :: router 이동 후 액션
	 * @param _force :: 강제 새로 고침 여부
	 */
	goTo: (_this, _router, _replace = true, _force = false, _then = (_router) => {
		console.log(_router)
	}) => {
		const onComplete = () => {
			_this.$nextTick(() => {
				if (_then instanceof Function) {
					_then(_router)
				}
			})
		}

		const onAbort = (a) => {
			if (a.name === "NavigationDuplicated") {
				_this.$forceUpdate()
				if (_force) {
					let cloneQuery = JSON.parse(JSON.stringify(_this.$router.currentRoute.query))
					_this.$router.push({query: Object.assign(cloneQuery, {_r: new Date().getTime()})})
						.then(() => {
							if (_then instanceof Function) {
								_then(_router)
							}
						})
				}
			} else {
				let path = _this.$router.options.routes.filter(e => e.name === _router.name)[0].path
				if (_router.params) {
					for (const [key, value] of Object.entries(_router.params)) {
						let regexp = `/:(${key})+[?]*/`
						path.replace(new RegExp(regexp), `${value}`)
					}
					location.href = path
				}
			}
		}

		if (!(_router instanceof Object)) {
			try {
				new URL(_router)
				if (this.getSubdomainWithURLString(_router) !== this.getSubdomain()) {
					location.href = _router
				} else {
					_router = {path: _router.replace(location.origin), replace: _replace}
				}
			} catch (e) {
				_router = {path: _router, replace: _replace}
			}
		}
		if (!_router.replace) {
			_router.replace = _replace
		}
		if (_this instanceof Vue) {
			if (!_router.path && !_router.name) {
				_router.replace ? delete _router.replace : () => {
				}
				_this.$router.push({
						query: _router,
						replace: _replace
					}
					, () => {
						onComplete()
					}
					, (a) => {
						onAbort(a)
					}
				)
				return
			}
			_this.$router.push(_router
				, () => {
					onComplete()
				}
				, (a) => {
					onAbort(a)
				}
			)
		} else {
			if (_router.path) {
				location.href = _router.path
			}
		}
	},


}
export default WebUtils;