<template>
	<div>
		<vue-topprogress ref="topProgress" :speed="1000" :height="7" color="#29d"></vue-topprogress>
		<vue-topprogress ref="topProgress1" :speed="800" :height="5" color="#bf0"></vue-topprogress>
		<vue-topprogress ref="topProgress2" :speed="600" :height="3" color="#f00"></vue-topprogress>
		<vue-topprogress ref="topProgress3" :speed="400" :height="1" color="#8a2be2"></vue-topprogress>

		<vue-topprogress ref="topProgress4" :speed="900" :height="1" color="#2554b3"></vue-topprogress>

<!--		<vue-topprogress v-for="item in progressList" :ref="item" :speed="500" :height="5"></vue-topprogress>-->
	</div>
</template>

<script>


import vueTopprogress from 'vue-top-progress'
import Vue from "vue";

Vue.use(vueTopprogress)

export default {
	name: "progressBars",
	data() {

		/*var progressList = [];
		for (let i = 1; i <= 10; i++) {
			progressList.push("csProcress" + i)
		}
		console.log(progressList)*/

		return {
			//progressList,
		}
	},
	created() {

		this.$eventBus.$on("startProgress", (_num)=> {
			if ( _num === undefined ) {
				this.$refs.topProgress.start()
			} else {
				this.$refs['topProgress' + _num].start()
			}
		})

		this.$eventBus.$on("doneProgress", (_num)=> {
			if ( _num === undefined ) {
				this.$refs.topProgress.done()
			} else {
				this.$refs['topProgress' + _num].done()
			}
		})

	},
	mounted() {

	},
	methods: {

	},
}
</script>

<style scoped>

</style>