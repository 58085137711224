export default {

	shuffle: function (array) {
		array.sort(() => Math.random() - 0.5);
	},


	objSorter: function (originObj, sortKey, ...keyPath) {
		let sortedObj = {}
		let exceptSortObj = {}
		let copiedOriginObj = JSON.parse(JSON.stringify(originObj))

		const getValue = (e, path) => {
			let result = e
			for (let i in path) {
				result = result[path[i]]
			}
			return result
		}

		if (!(Object.values(copiedOriginObj).map(e => getValue(e, keyPath)).sort(function (a, b) {
			return a - b
		}).every((val, i) => val === sortKey.sort(function (a, b) {
			return a - b
		})[i]))) {
			Object.keys(copiedOriginObj).filter(e => sortKey.indexOf(getValue(copiedOriginObj[e], keyPath)) < 0).forEach(e => {
				exceptSortObj[e] = copiedOriginObj[e]
				delete copiedOriginObj[e]
			})
		}

		Object.values(copiedOriginObj).forEach((e, i) => {
			sortedObj[Object.keys(copiedOriginObj)[Object.values(copiedOriginObj).map(e => {
				return sortKey.indexOf(getValue(e, keyPath))
			})[i]]] = Object.values(copiedOriginObj).filter(e => getValue(e, keyPath) === sortKey[i])[0]
		})

		return Object.assign(sortedObj, exceptSortObj)
	}
}