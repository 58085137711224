import Vue from 'vue'
import $ from 'jquery'

/*Vue.filter("nl2br", (entry) => {

	var reg = /\n/g;
	if (entry && entry !== null) {
		let i, s = '';
		let lines = entry.split(reg)
		let l = lines.length;

		for (i = 0; i < l; ++i) {
			s += lines[i];
			(i !== l - 1) && (s += '<br/>');
		}

		return s;
	}

	console.log(entry)
	return entry;
});*/

Vue.filter("nl2br", (entry) => {
	if(!entry){
		return ''
	}
	//console.log(entry)
	return entry.replace(/\r\n|\n/g, '<br />')
});

Vue.filter("br2nl", (entry) => {
	return entry.replace(/<br\s*\/?>/mg, "\n")
});


Vue.filter("isEmpty", (args) => {
	if ( $.trim( args ).length <= 0 || args === null || args === undefined ) {
		return true;
	} else {
		return false;
	}
})


/**
 * 서비스 신청 - 수집 희망 채널 필터
 */
Vue.filter('requestChannelFilter', function (_channel) {

	let str = "";
	switch ( _channel ) {
		case "cafe" :
			str = "카페24"
			break;
		case "godo" :
			str = "고도몰/샵바이";
			break;
		case "erp" :
			str = "ERP";
			break;
		case "data" :
			str = "고객행동데이터";
			break;
		case "datapresso" :
			str = "데이터프레소";
			break;
		case "etc" :
			str = "기타";
			break;
		case "shop" :
			str = "샵링커";
			break;
		case "ensol" :
			str = "에너지솔루션";
			break;
		case "anal" :
			str = "검색어분석";
			break;
		case "consul" :
			str = "컨설팅";
			break;
	}
	return str;
})

/**
 * 서비스 신청 - 승인 여부 필터
 */
Vue.filter('statusCodeFilter', function (_status) {

	let str = "";
	switch ( _status ) {
		case "confirm" :
			str = "승인"
			break;
		case "request" :
			str = "승인 요청";
			break;
		case "cancel" :
			str = "승인 취소";
			break;
	}
	return str;
})

/**
 * 결제 수단 or 납부 방법 필터
 */
Vue.filter('billPaymentFilter', function (_payment) {

	console.log('::::paymens', _payment)
	let str = "";
	switch ( _payment ) {
		case "Card" :
			str = "신용카드"
			break;
	}
	return str;
})


/**
 * 서비스 결제 유형 필터
 */
Vue.filter('paymentFilter', function (_payment) {

	let str = "";
	switch ( _payment ) {
		case "free" :
			str = "무료 체험"
			break;
		case "stop" :
			str = "결제 중지";
			break;
		case "paying" :
			str = "정기 결제";
			break;
	}
	return str;
})

/**
 * [목록] 수집 희망 채널 필터
 * 콤마를 기준으로 쪼개고 다시 필터링
 */
Vue.filter('stringToArrayForService', function (_string) {

	if(_string==undefined) {return;}

	let array = _string.split(',')
	let newArray = [];

	for(let i=0; i<array.length; i++){
		newArray.push( filter(array[i]) );
	}

	return newArray.join(', ');
})

function filter(_one) {
	let str;
	switch ( _one ) {
		case "cafe" :
			str = "카페24"
			break;
		case "godo" :
			str = "고도몰/샵바이";
			break;
		case "erp" :
			str = "ERP";
			break;
		case "data" :
			str = "고객행동데이터";
			break;
		case "datapresso" :
			str = "데이터프레소";
			break;
		case "etc" :
			str = "기타";
			break;
		case "infra" :
			str = "인프라비용";
			break;
		case "add_proj" :
			str = "추가구축비";
			break;
		case "shop" :
			str = "샵링커";
			break;
		case "ensol" :
			str = "에너지솔루션";
			break;
		case "anal" :
			str = "검색어분석";
			break;
		case "consul" :
			str = "컨설팅";
			break;
		case "consulting" :
			str = "컨설팅비용";
			break;
		default:
			str = _one
			break;
	}
	return str;
}
