
// add library
import $ from 'jquery'
// import jQuery from 'jquery'
window.$ = $
export default {

	setOnlyNum: function (name) {
		var valueByName = $('input[name='+name+']').val().replace(/[^0-9]/g,'');
		$('input[name='+name+']').val(valueByName);
	},

}