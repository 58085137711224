export default {

	methods: {

		KeywordVO: function() {
			this.sidx = 0;
			this.title = "";
			this.isShow; //노출 여부 ( Y/N ), Y는 1개 이하만 존재할 수 있음.
			this.searchOption; // placeholder( P ), value ( V )
			this.placeHolder = "검색어를 입력해 주세요."; // placeholder 값
			this.inputValue = ""; //value 값
			this.keywords = []; // 키워드 값 ( , , ) ...

		}

	}

}