// import webUtils from "@/commons/utils/web.utils";

export default {
	methods : {
		ServiceApplyVO: function($_session) {
			if($_session!=null && $_session.serviceApplyVO!=undefined && $_session.serviceApplyVO!=null) {
				const applyVO = $_session.serviceApplyVO;
				this.idx = 0;
				this.emailId = ($_session != null) ? $_session.emailId : "";
				this.orderNumber = applyVO.orderNumber;
				this.projectName = applyVO.projectName;
				this.companyName = applyVO.companyName;
				this.managerName = applyVO.managerName;
				this.reqChannel = applyVO.reqChannel;
				this.backgroundColor = applyVO.backgroundColor;
				this.fidx = applyVO.fidx;
				this.reqDate = applyVO.reqDate;
				this.reqMsg = applyVO.reqMsg;
				this.clientId = applyVO.clientId;
				this.secretKey = applyVO.secretKey;
				this.mailId = applyVO.mailId;
				this.redirectUrl = applyVO.redirectUrl;
				this.partnerId = applyVO.partnerId;
				this.erpName = applyVO.erpName;
				this.webUrl = applyVO.webUrl;
				this.statusCode = applyVO.statusCode;
				this.startDate = applyVO.startDate;
				this.endDate = applyVO.endDate;
				this.payDate = applyVO.payDate;
				this.agreeAutoPayment = applyVO.agreeAutoPayment;
				this.infoConfirm = applyVO.infoConfirm;
			} else {
				this.idx = 0;
				this.emailId = ($_session != null) ? $_session.emailId : "";
				this.projectName = "";
				this.orderNumber = "";
				this.companyName = "";
				this.managerName = "";
				this.reqChannel = "cafe";
				this.backgroundColor = "";
				this.fidx = null;
				this.reqDate = "";
				this.reqMsg = "";
				this.clientId = "";
				this.secretKey = "";
				this.mailId = "";
				this.redirectUrl = "";
				this.partnerId = "";
				this.erpName = "";
				this.webUrl = "";
				this.statusCode = "";
				this.startDate = "";
				this.endDate = "";
				this.payDate = "";
				this.agreeAutoPayment = false;
				this.infoConfirm = false;
			}
		}
	}
}