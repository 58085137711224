import $ from 'jquery'

const MediaUtils = {


	audioPlay( _dom ) {
		var audio = $(_dom);
		audio.get(0).play();
	},


	audioPause( _dom ) {
		var audio = $(_dom);
		audio.get(0).pause();
	}


}
export default MediaUtils;