import Vue from 'vue'
import moment from 'moment'




Vue.filter("dateFormatFilter", (entry, format) => {
	return moment(entry).format(format);
});

Vue.filter("dateFilter", (entry) => {
	return moment(entry).format("YYYY.MM.DD");
});

Vue.filter("dateFilterDay", (entry) => {

	entry = new Date(entry)
	entry.setDate(entry.getDate() - 1);

	return moment(entry).format("YYYY.MM.DD");
});

Vue.filter("datetimeFilter", (entry) => {
	return entry !== '' ? moment(entry).format("YYYY.MM.DD HH:mm:ss") : '';
});

Vue.filter("timeFilter", (entry) => {
	return moment(entry).format("HH:mm:ss");
});


Vue.filter('scheduleFilter', function (date, mode) {

	if ( date === undefined || date.length <= 0 ) {
		return "";
	}

	let str = "";
	switch (mode) {
		case "month":
			str = "매년 " + moment(date, 'YYYY-MM-DD').format("MM") + '월'
			break;
		case "day":
			str = "매월 " + moment(date, 'YYYY-MM-DD').format("DD") + '일'
			break;
		case "fix":
			str = moment(date, 'YYYY-MM-DD').format('YYYY.MM.DD')
			break;
		default:
			str = moment(date, 'YYYY-MM-DD').format('YYYY.MM.DD')
			break;
	}

	return str;
})